import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';

let isOperationPresent = false;

Blockly.Blocks['sql_operation'] = {
  init: function () {
    this.appendDummyInput().appendField('OPERAÇÃO');
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(210);
    this.setTooltip('Bloco base para operações SQL');
    this.setHelpUrl('');
  },
  onchange: function () {
    const workspace = this.workspace;

    if (workspace) {
      const blocks = workspace.getAllBlocks();
      const operationBlocks = blocks.filter((block) => block.type.startsWith('sql_') && block.type !== 'sql_operation');

      isOperationPresent = operationBlocks.length > 0;
    }
  }
};

Blockly.Blocks['sql_selection'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('SELECIONAR')
      .appendField(new Blockly.FieldTextInput('atributo'), 'FIELD1')
      .appendField(
        new Blockly.FieldDropdown([
          ['=', '='],
          ['<', '<'],
          ['<=', '<='],
          ['>', '>'],
          ['>=', '>=']
        ]),
        'FIELD2'
      )
      .appendField(new Blockly.FieldTextInput('valor'), 'FIELD3')
      .appendField('DA TABELA')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução SELECT com cláusula WHERE');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_selection'] = function (block) {
  const field1 = block.getFieldValue('FIELD1');
  const field2 = block.getFieldValue('FIELD2');
  const field3 = block.getFieldValue('FIELD3');
  const tableVar = block.getFieldValue('TABLE_VAR');

  if (!tableVar) {
    return '/* Variável de tabela ausente */';
  }

  const allowedFields = {
    estudantes: ['nome', 'sobrenome', 'id', 'idade'],
    cursos: ['id', 'nome'],
    professores: ['id', 'nome', 'sobrenome'],
    estudante_curso: ['estudante_id', 'curso_id'],
    professor_curso: ['professor_id', 'curso_id'],
    estudantes_A: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_B: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_C: ['nome', 'sobrenome', 'id', 'idade']
  };

  if (tableVar in allowedFields) {
    if (!allowedFields[tableVar].includes(field1)) {
      throw new Error(`Campo não permitido para a tabela ${tableVar}. Use apenas ${allowedFields[tableVar].join(', ')}.`);
    }
  } else {
    throw new Error(`Tabela não permitida: ${tableVar}`);
  }

  const code = `SELECT * FROM '${tableVar}' WHERE ${field1} ${field2} '${field3}'`;
  return `${code} & ${tableVar}`;
};

Blockly.Blocks['sql_projection'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('PROJETAR')
      .appendField(new Blockly.FieldTextInput('atributo'), 'FIELD1')
      .appendField('DA TABELA')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução PROJECT');
    this.setHelpUrl('');
  },
  onchange: function () {
    if (!this.getFieldValue('TABLE_VAR')) {
      this.setWarningText('Por favor, forneça uma variável de tabela.');
    } else {
      this.setWarningText(null);
    }
  }
};

javascriptGenerator['sql_projection'] = function (block) {
  const field1 = block.getFieldValue('FIELD1');
  const tableVar = block.getFieldValue('TABLE_VAR');

  if (!tableVar) {
    return '/* Variável de tabela ausente */';
  }

  const allowedFields = {
    estudantes: ['*', 'nome', 'sobrenome', 'id', 'idade'],
    cursos: ['*', 'id', 'nome'],
    professores: ['*', 'id', 'nome', 'sobrenome'],
    estudante_curso: ['*', 'estudante_id', 'curso_id'],
    professor_curso: ['*', 'professor_id', 'curso_id'],
    estudantes_A: ['*', 'nome', 'sobrenome', 'id', 'idade'],
    estudantes_B: ['*', 'nome', 'sobrenome', 'id', 'idade'],
    estudantes_C: ['*', 'nome', 'sobrenome', 'id', 'idade']
  };

  if (tableVar in allowedFields) {
    if (!allowedFields[tableVar].includes(field1)) {
      throw new Error(`Campo não permitido para a tabela ${tableVar}. Use apenas ${allowedFields[tableVar].join(', ')}.`);
    }
  } else {
    throw new Error(`Tabela não permitida: ${tableVar}`);
  }

  const code = `SELECT ${field1} FROM '${tableVar}'`;
  return `${code} & ${tableVar}`;
};

Blockly.Blocks['sql_groupBy'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('AGRUPAR')
      .appendField(new Blockly.FieldTextInput('atributo'), 'FIELD')
      .appendField('DA TABELA')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução AGRUPAR');
    this.setHelpUrl('');
  },
  onchange: function () {
    if (!this.getFieldValue('TABLE_VAR')) {
      this.setWarningText('Por favor, forneça uma variável de tabela.');
    } else {
      this.setWarningText(null);
    }
  }
};

javascriptGenerator['sql_groupBy'] = function (block) {
  const field = block.getFieldValue('FIELD');
  const tableVar = block.getFieldValue('TABLE_VAR');

  if (!tableVar) {
    return '/* Variável de tabela ausente */';
  }

  const allowedFields = {
    estudantes: ['nome', 'sobrenome', 'id', 'idade'],
    cursos: ['id', 'nome'],
    professores: ['id', 'nome', 'sobrenome'],
    estudante_curso: ['estudante_id', 'curso_id'],
    professor_curso: ['professor_id', 'curso_id'],
    estudantes_A: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_B: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_C: ['nome', 'sobrenome', 'id', 'idade']
  };

  if (tableVar in allowedFields) {
    if (!allowedFields[tableVar].includes(field)) {
      throw new Error(`Campo não permitido para a tabela ${tableVar}. Use apenas ${allowedFields[tableVar].join(', ')}.`);
    }
  } else {
    throw new Error(`Tabela não permitida: ${tableVar}`);
  }

  const code = `SELECT ${field} FROM ${tableVar} GROUP BY ${field} ORDER BY ${field};`;
  return `${code} & ${tableVar}`;
};

Blockly.Blocks['sql_rename'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('RENOMEAR')
      .appendField(new Blockly.FieldTextInput('atributo'), 'FIELD1')
      .appendField(new Blockly.FieldTextInput('novo nome'), 'FIELD2')
      .appendField('DA TABELA')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução AGRUPAR');
    this.setHelpUrl('');
  },
  onchange: function () {
    if (!this.getFieldValue('TABLE_VAR')) {
      this.setWarningText('Por favor, forneça uma variável de tabela.');
    } else {
      this.setWarningText(null);
    }
  }
};

javascriptGenerator['sql_rename'] = function (block) {
  const field1 = block.getFieldValue('FIELD1');
  const field2 = block.getFieldValue('FIELD2');
  const tableVar = block.getFieldValue('TABLE_VAR');

  if (!tableVar) {
    return '/* Variável de tabela ausente */';
  }

  const allowedFields = {
    estudantes: ['nome', 'sobrenome', 'id', 'idade'],
    cursos: ['id', 'nome'],
    professores: ['id', 'nome', 'sobrenome'],
    estudante_curso: ['estudante_id', 'curso_id'],
    professor_curso: ['professor_id', 'curso_id'],
    estudantes_A: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_B: ['nome', 'sobrenome', 'id', 'idade'],
    estudantes_C: ['nome', 'sobrenome', 'id', 'idade']
  };

  if (tableVar in allowedFields) {
    if (!allowedFields[tableVar].includes(field1)) {
      throw new Error(`Campo não permitido para a tabela ${tableVar}. Use apenas ${allowedFields[tableVar].join(', ')}.`);
    }
  } else {
    throw new Error(`Tabela não permitida: ${tableVar}`);
  }

  const code = `SELECT ${field1} AS '${field2}' FROM ${tableVar};`;
  return `${code} & ${tableVar}`;
};

Blockly.Blocks['sql_naturalJoin'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('NATURAL JOIN')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR1'
      )
      .appendField('COM')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR2'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução NATURAL JOIN');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_naturalJoin'] = function (block) {
  const tableVar1 = block.getFieldValue('TABLE_VAR1');
  const tableVar2 = block.getFieldValue('TABLE_VAR2');

  if (!tableVar1 || !tableVar2) {
    return '/* Variável de tabela ausente */';
  }

  const code = `SELECT * FROM ${tableVar1} NATURAL JOIN ${tableVar2};`;
  return `${code} & ${tableVar1}`;
};

Blockly.Blocks['sql_crossJoin'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('CROSS JOIN')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudante_curso', 'estudante_curso'],
          ['professor_curso', 'professor_curso'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR1'
      )
      .appendField('COM')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['cursos', 'cursos'],
          ['professores', 'professores'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR2'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução CROSS JOIN');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_crossJoin'] = function (block) {
  const tableVar1 = block.getFieldValue('TABLE_VAR1');
  const tableVar2 = block.getFieldValue('TABLE_VAR2');

  if (!tableVar1 || !tableVar2) {
    return '/* Variável de tabela ausente */';
  }

  if (tableVar1 == tableVar2) {
    throw new Error('As tabelas não podem ser iguais.');
  }

  const code = `SELECT * FROM ${tableVar1} CROSS JOIN ${tableVar2};`;
  return `${code} & ${tableVar1}`;
};

Blockly.Blocks['sql_union'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('UNIÃO')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR1'
      )
      .appendField('COM')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR2'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução CROSS JOIN');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_union'] = function (block) {
  const tableVar1 = block.getFieldValue('TABLE_VAR1');
  const tableVar2 = block.getFieldValue('TABLE_VAR2');

  if (!tableVar1 || !tableVar2) {
    return '/* Variável de tabela ausente */';
  }

  const code = `SELECT * FROM ${tableVar1} UNION SELECT * FROM ${tableVar2};`;
  return `${code} & ${tableVar1}`;
};

Blockly.Blocks['sql_intersect'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('INTERSECTAR')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR1'
      )
      .appendField('COM')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR2'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução CROSS JOIN');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_intersect'] = function (block) {
  const tableVar1 = block.getFieldValue('TABLE_VAR1');
  const tableVar2 = block.getFieldValue('TABLE_VAR2');

  if (!tableVar1 || !tableVar2) {
    return '/* Variável de tabela ausente */';
  }

  const code = `SELECT * FROM ${tableVar1} INTERSECT SELECT * FROM ${tableVar2};`;
  return `${code} & ${tableVar1}`;
};

Blockly.Blocks['sql_difference'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('DIFERENÇA')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR1'
      )
      .appendField('COM')
      .appendField(
        new Blockly.FieldDropdown([
          ['estudantes', 'estudantes'],
          ['estudantes_A', 'estudantes_A'],
          ['estudantes_B', 'estudantes_B'],
          ['estudantes_C', 'estudantes_C']
        ]),
        'TABLE_VAR2'
      );
    this.setInputsInline(true);
    this.setPreviousStatement(true, 'sql_table');
    this.setNextStatement(true, 'sql_operation');
    this.setColour(210);
    this.setTooltip('Bloco para a instrução CROSS JOIN');
    this.setHelpUrl('');
  }
};

javascriptGenerator['sql_difference'] = function (block) {
  const tableVar1 = block.getFieldValue('TABLE_VAR1');
  const tableVar2 = block.getFieldValue('TABLE_VAR2');

  if (!tableVar1 || !tableVar2) {
    return '/* Variável de tabela ausente */';
  }

  const code = `SELECT * FROM ${tableVar1} EXCEPT SELECT * FROM ${tableVar2};`;
  return `${code} & ${tableVar1}`;
};

export const getDefaultToolbox = (): Blockly.utils.toolbox.ToolboxInfo => {
  return {
    kind: 'categoryToolbox',
    contents: [
      {
        kind: 'category',
        name: 'Operadores',
        colour: '259',
        contents: [
          {
            kind: 'block',
            type: 'sql_selection'
          },
          {
            kind: 'block',
            type: 'sql_projection'
          },
          {
            kind: 'block',
            type: 'sql_groupBy'
          },
          {
            kind: 'block',
            type: 'sql_rename'
          },
          {
            kind: 'block',
            type: 'sql_naturalJoin'
          },
          {
            kind: 'block',
            type: 'sql_crossJoin'
          },
          {
            kind: 'block',
            type: 'sql_union'
          },
          {
            kind: 'block',
            type: 'sql_intersect'
          },
          {
            kind: 'block',
            type: 'sql_difference'
          }
        ]
      }
    ]
  };
};
