import { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import { BlocklyWorkspace } from 'react-blockly';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { DEFAULT_OPTIONS, getDefaultToolbox } from './blockly';
import axios from 'axios';
import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';

const toolbox = getDefaultToolbox();

const TutorialModal = ({ show, handleClose, handleVerTutorial }) => (
  <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
    <Modal.Header closeButton>
      <Modal.Title>Tutorial</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Deseja fazer o tutorial de como usar o Algebra Blocks?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={handleClose}>
        Pular Tutorial
      </Button>
      <Button variant="primary" onClick={handleVerTutorial}>
        Ver Tutorial
      </Button>
    </Modal.Footer>
  </Modal>
);

const BlurModal = ({ show, handleClose, title, text }) => (
  <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Fechar
      </Button>
    </Modal.Footer>
  </Modal>
);

const executeQuery = async (query) => {
  try {
    const { data } = await axios.post('https://algebra-query.dokehostcombr.workers.dev/api/query', { query }, { headers: { 'Content-Type': 'application/json' } });
    return data;
  } catch (error) {
    throw new Error('Ocorreu um erro na execução da consulta.');
  }
};

const renderTable = (data, containerClassName) => {
  if (!data || data.length === 0) {
    return null;
  }

  const headers = Object.keys(data[0]);

  return (
    <div className={`table-container ${containerClassName}`}>
      <table className="table table-bordered table-striped">
        <thead className="thead-dark">
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const App = () => {
  const [queryResult, setQueryResult] = useState([]);
  const [TableTitle, setTableTitle] = useState('');
  const [tableData, setTableData] = useState([]);
  const [generatedSql, setGeneratedSql] = useState('');
  const [tutorialModal, setTutorialModal] = useState({ show: true, title: 'Tutorial', text: 'Deseja fazer o tutorial?' });
  const [blurModal, setBlurModal] = useState({ show: false, title: '', text: '' });

  const handleTutorialModalClose = () => setTutorialModal({ ...tutorialModal, show: false });
  const handleBlurModalClose = () => setBlurModal({ ...blurModal, show: false });

  const handleVerTutorial = () => {
    window.open('https://youtu.be/1ugI60ZCpqk?si=iD_oJzoJVX1vSkfN', '_blank');
    handleTutorialModalClose();
  };

  const validateOperationBlock = () => {
    const workspace = Blockly.getMainWorkspace();
    const operationBlocks = workspace.getAllBlocks(false);

    if (operationBlocks.length > 1) {
      setBlurModal({ show: true, title: 'Erro', text: 'Você só pode adicionar uma operação por vez.' });
      return false;
    }

    return true;
  };

  const criarConsultaPadrao = (sql) => {
    try {
      const tabela = extrairTabelaDaSQL(sql);
      return `SELECT * FROM ${tabela}`;
    } catch (error) {
      throw new Error('Não foi possível criar a consulta padrão.');
    }
  };

  const extrairTabelaDaSQL = (sql) => {
    const regex = /\bFROM\s+([^\s;]+)/i;
    const match = sql.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      throw new Error('Não foi possível extrair o nome da tabela.');
    }
  };

  const runQuery = async () => {
    try {
      const workspace = Blockly.getMainWorkspace();

      if (!validateOperationBlock()) {
        return;
      }

      const resultStrings = javascriptGenerator.workspaceToCode(workspace);

      const result = resultStrings.split('&');
      let query = result[0];
      let tableTitle = result[1];

      const table = criarConsultaPadrao(query);

      const [queryResult, tableData] = await Promise.all([executeQuery(query), executeQuery(table)]);

      setGeneratedSql(query);
      setQueryResult(queryResult);
      setTableData(tableData);
      setTableTitle(tableTitle);
    } catch (e) {
      setBlurModal({ show: true, title: 'Erro', text: e.message });
    }
  };

  const clearResults = () => {
    setQueryResult([]);
    setTableTitle('');
    setTableData([]);
    setGeneratedSql('');
  };

  useEffect(() => {
    if (tutorialModal.show) {
      const tutorialTimer = setTimeout(() => {
        handleTutorialModalClose();
      }, 30000);
      return () => clearTimeout(tutorialTimer);
    }
  }, [tutorialModal.show]);

  return (
    <Card className="vh-100 d-flex flex-column">
      <Card.Header>
        <Card.Title>Algebra Blocks - UFU</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex p-0">
        <Row className="h-100 w-100 m-0">
          <Col xs={12} md={8} className="p-3">
            <BlocklyWorkspace className="blockly-editor w-100 h-100" toolboxConfiguration={toolbox} workspaceConfiguration={DEFAULT_OPTIONS} initialXml={''} />
          </Col>
          <Col xs={12} md={4} className="p-3 bg-light d-flex flex-column">
            <Button variant="primary" onClick={runQuery} className="mb-3 w-100">
              Iniciar Consulta
            </Button>
            <div className="mb-3 border rounded p-2 flex-grow-1">
              <strong>Resultado da Consulta:</strong>
              {renderTable(queryResult, 'result-table')}
            </div>
            <div className="border rounded p-2 flex-grow-1">
              <strong>Dados da Tabela: {TableTitle}</strong>
              {renderTable(tableData, 'table-data')}
            </div>
            <Button variant="danger" onClick={clearResults} className="w-100 mt-3">
              Limpar Resultados
            </Button>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex flex-column">
        <strong>SQL Gerado:</strong>
        <pre className="flex-grow-1 overflow-auto">{generatedSql}</pre>
      </Card.Footer>
      {tutorialModal.show && <TutorialModal show={tutorialModal.show} handleClose={handleTutorialModalClose} handleVerTutorial={handleVerTutorial} />}
      {blurModal.show && <BlurModal show={blurModal.show} handleClose={handleBlurModalClose} title={blurModal.title} text={blurModal.text} />}
    </Card>
  );
};

export default App;
